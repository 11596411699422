<template>
  <div class="verify-dialog">
    <div class="px-10 text-center">
      <span class="mt-10 d-block font-20 black--text font-500">
       {{$t('waitingToAccept')}}</span
      >
      <span class="mt-3 d-block font-20 primary--text font-300">
        {{$t('pleaseWait')}}</span
      >
      <img
        class="my-14"
        :src="require('@/assets/images/draw/ship-popup.png')"
        alt=""
      />
      <v-btn
        class="c-btn mb-10"
        color="primary"
        min-width="240"
        height="40"
        @click="$emit('close')"
        ><span class="white--text font-300 font-20">{{$t('ok')}}</span></v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss"></style>
