<template>
  <div>
    <!-- head -->

    <div class="max-width">
      <!-- add new -->
      <div class="d-flex align-center justify-space-between my-10">
        <v-row align="center">
          <v-col cols="6" md="4">
            <span class="font-30 primary--text font-500">{{
              $t("myShipments")
            }}</span>
          </v-col>

          <v-col cols="6" md="8">
            <!-- add new -->
            <div class="d-flex justify-end w-100">
              <v-btn
                class="c-btn px-1"
                color="primary"
                elevation="0"
                height="40"
                @click="$router.push('/add-shipment')"
                ><span class="font-300 font-20 white--text px-6">{{
                  $t("addShipment")
                }}</span></v-btn
              >
            </div>
          </v-col>
        </v-row>
      </div>
      <!-- content -->
      <v-row v-if="loading" class="mt-2">
        <v-col cols="12" md="4" v-for="n in 3" :key="n">
          <skeleton-card />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" md="4" v-for="(item, i) in items" :key="i">
          <product-card :item="item">
            <template name="slotBtn">
              <div class="d-flex align-center justify-center mt-10">
                <v-btn
                  v-if="item.is_requested"
                  class="c-btn mx-2"
                  elevation="0"
                  height="35"
                  :disabled="true"
                  ><span class="white--text font-20 font-300">{{
                    $t("shiped")
                  }}</span></v-btn
                >
                <v-btn
                v-else
                  class="c-btn mx-2"
                  elevation="0"
                  height="35"
                  color="primary"
                  @click="requestHandler(item.id,item.order_id)"
                ><span class="white--text font-20 font-300">{{
                    $t("ship")
                  }}</span></v-btn
                >
                <!-- {{ item.status }} -->
                <v-btn
                  v-if="item.status == 'aggrement'"
                  class="c-btn mx-2"
                  color="primary"
                  elevation="0"
                  height="35"
                 
                  ><span class="primary--text font-20 font-300">{{
                    $t("shiped")
                  }}</span></v-btn
                >

                <!-- view shipment id -->
                <router-link to="/profile/shipments">
                  <v-btn
                    class="c-btn px-1"
                    color="primary"
                    elevation="0"
                    height="35"
                    ><img
                      :src="require('@/assets/images/icons/add.png')"
                      alt=""
                  /></v-btn>
                </router-link>
              </div>
            </template>
          </product-card>
        </v-col>
      </v-row>

      <!-- if empty -->
      <div class="text-center mt-12" v-if="items.length == 0 && !loading">
        <img :src="require('@/assets/images/draw/shipment-empty.png')" alt="" />
        <span class="font-30 black--text d-block my-4">{{
          $t("youDonthaveShipments")
        }}</span>
        <v-btn
          class="c-btn mx-3 mt-6 mb-14"
          color="secondary"
          min-width="240"
          height="40"
          @click="$router.push('/add-shipment')"
          ><span class="white--text font-300 font-20">{{
            $t("addShipment")
          }}</span></v-btn
        >
      </div>
    </div>

    <!-- ship dialog -->
    <v-dialog v-model="shipDialog" max-width="400" content-class="white">
      <shipment-wait-dialog @close="shipDialog = false"></shipment-wait-dialog>
    </v-dialog>
  </div>
</template>

<script>
import ProductCard from "@/components/core/ProductCard.vue";
import ShipmentWaitDialog from "../../../components/dialogs/ShipmentWait.vue";
export default {
  components: { ProductCard, ShipmentWaitDialog },
  data: () => ({
    items: [],
    keyUpdated: false,
    shipDialog: false,
    loading: false,
  }),
  methods: {
    async requestHandler(id,order_id) {
      let formData = new FormData();
      formData.append("trip_id", this.$route.params.id);
      formData.append("shipment_id", id);
      formData.append("order_id", order_id);
      const res = await this.$axios.post("/sendBidAsSender", formData);
      if (res.data) {
        this.$store.dispatch("showSnack", {
          text: res.data.message,
          color: "success",
        });
        this.shipDialog = true;
        this.getData();
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
    async getData() {
      this.loading = true;
      let { data } = await this.$axios.get("/getMyShipmentsDetails");
      if (data.data) {
        this.loading = false;
        const item1 = await this.$store.dispatch(
          "returnShipmentArray",
          data.data
        );
        const item2 = await this.$store.dispatch(
          "returnProductsArray",
          data.data
        );

        this.items = [...item1, ...item2]
        .filter(item=> item.status =='pending');
      }
    },
  },
    computed: {
    userID() {
      return localStorage.getItem("userID");
    },
  },
  created() {
    this.getData();
  },
};
</script>
